'use client'

import '../index.css'

import { ReactNode } from 'react'
import { Providers } from '../providers'
import { Container } from './container'

export default function Layout({ children }: { children: ReactNode }) {
  return (
    <Providers>
      <Container>{children}</Container>
    </Providers>
  )
}
